<template>
    <v-autocomplete
        v-model="condition"
        v-bind="$attrs"
        :items="conditions"
        :aria-required="required"
        :disabled="disabled"
        hideDetails="auto"
        dense
        outlined
    />
</template>

<script>
export default {
    name: 'DropdownRRACondition',
    props: {
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            conditions: [
                'All',
                'New',
                'Used',
            ],
        };
    },
    computed: {
        condition: {
            get() {
                return this.value;
            },
            set( value ) {
                this.$emit( 'input', value );
            },
        },
    },
};
</script>
